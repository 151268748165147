import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./mobilityDigitalAcceleration.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";
import ContactFormService from "../../components/ContactFormService";
import ServiceKeyPoints from "../../components/ServiceKeyPoints";

const MobilityDigitalAcceleration = () => {
  const keyPoints = [
    {
      id: "1",
      title: "Strategic Planning & Stakeholder Engagements",
      desc: "Crafting comprehensive strategies and engaging stakeholders for alignment.",
    },
    {
      id: "2",
      title: "Digital Strategy Development",
      desc: "Developing forward-thinking digital strategies to drive business innovation and growth.",
    },
    {
      id: "3",
      title: "Technology Stack Selection",
      desc: "Choosing the optimal technology stack for high performance and scalability.",
    },
    {
      id: "4",
      title: "Data Security and Compliance",
      desc: "Ensuring the highest standards of data security and compliance.",
    },
    {
      id: "5",
      title: "Integration with Existing Systems",
      desc: "Seamless integration with existing systems for enhanced functionality and efficiency.",
    },
    {
      id: "6",
      title: "Testing and Quality Assurance",
      desc: "Rigorous testing and quality assurance for reliable and high-performance.",
    },
    {
      id: "7",
      title: "Deployment",
      desc: "Efficient deployment strategies for smooth and successful rollouts.",
    },
    {
      id: "8",
      title: "Performance Monitoring and Optimization",
      desc: "Monitoring and Optimization: Continuous performance monitoring and optimization for sustained digital excellence.",
    },
    {
      id: "9",
      title: "Feedback Loops and Continuous Improvement",
      desc: "Implementing feedback loops for ongoing improvements and staying ahead in the digital landscape.",
    },
  ];

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Mobility and digital acceleration service provider"
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/mobility-digital-acceleration.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Mobility & Digital Acceleration</h1>
          <h6>
            Mobility and digital acceleration services for a connected tomorrow
          </h6>
        </div>
      </CommonBannerImage>
      <div className="mt-4">
        <Container>
          <Row>
            <Col
              md={6}
              className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}
            >
              <Image
                alt="mobility-digital"
                src="/assets/mobility-digital-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "450px", width: "100%" }}
              />
            </Col>
            <Col md={6} className={styles.anlyseContainer}>
              <p>
                Orbio comes with rich expertise in various visual, digital and
                design solutions that enable organizations of all sizes to
                identify new opportunities and innovate. We present a team of
                creative UI/UX designers with several decades of combined
                experience handling high-end global design projects. We work
                with the clients, understand their requirements and help them
                strategize the UI design, making the user experience unique and
                efficient..
              </p>
              <p>
                Businesses today are compelled to differentiate their products,
                services and customer experience to remain competitive in the
                online marketplace. Orbio has all the necessary tools and
                expertise to provide you with end-to-end e-commerce consulting
                and implementation solutions so you can scale your retail
                businesses at its highest potential.
              </p>
              <p>
                In addition to the aforementioned services, we provide you with
                unique mobility solutions that are tailor-made to your business
                requirements. Be it IOS or Android, our unique way of mobile
                application development guarantees visitor traction and superior
                user experience right from the first click
              </p>
              <p>
                Our digital marketing experts are here to answer all your
                business inquiries and serve as a one-stop shop for digital and
                creative solutions. What more? Bank on us for industry agnostic
                solutions across digital branding, lead generation, SEO and SEM
                services.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <ServiceKeyPoints heading="This is how we do it:" keyPoints={keyPoints} />
      <ServiceMainContent />
      <ContactFormService caseStudyLength={0} />
    </>
  );
};

export default MobilityDigitalAcceleration;
