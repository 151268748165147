import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./productEngineering.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";
import ContactFormService from "../../components/ContactFormService";
import ServiceKeyPoints from "../../components/ServiceKeyPoints";

const ProductEngineering = () => {
  const keyPoints = [
    {
      id: "1",
      title: "Ideation and Validation",
      desc: "Transforming visionary ideas into validated concepts ready for market success.",
    },
    {
      id: "2",
      title: "Market Research and Requirements Analysis",
      desc: "In-depth market research and requirements analysis for targeted, user-centric digital solutions.",
    },
    {
      id: "3",
      title: "Feasibility Study",
      desc: "Ensuring practicality and success potential with thorough feasibility studies.",
    },
    {
      id: "4",
      title: "Product Design and Prototyping",
      desc: "Crafting innovative designs and prototypes that set the stage for impactful digital products.",
    },
    {
      id: "5",
      title: "Engineering Analysis",
      desc: "Detailed engineering analysis to refine and perfect product functionality.",
    },
    {
      id: "6",
      title: "Development and Coding",
      desc: "Expert development and coding to bring digital solutions to life with precision and efficiency.",
    },
    {
      id: "7",
      title: "Verification and Validation",
      desc: "Rigorous verification and validation to guarantee product reliability and performance.",
    },
    {
      id: "8",
      title: "Quality Control and Testing",
      desc: "Comprehensive quality control and testing for high-standard digital products.",
    },
    {
      id: "9",
      title: "Post-launch Support and Optimization",
      desc: "Dedicated post-launch support and continuous optimization for sustained product excellence.",
    },
  ];

  let caseStudy = {
    id: "0",
    heading: "Process Engineering in the Legal Industry",
    desc: "Revolutionizing Your Clio Experience Through The Power Of Data Tracing, Troubleshooting and Scheduler Advancements.",
    img: "/assets/my-firm-data-lap-screen.WebP",
    url: "/case-study/process-engineering-in-the-legal-industry",
  };

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Product, process, and data engineering services"
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/product-engineering-bg.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Product Engineering</h1>
          <h6>Building future-proof digital solutions</h6>
        </div>
      </CommonBannerImage>
      <div>
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <p>
                From product design, to development, and, testing and
                deployment, our journey continues until end-user experiences are
                satisfied. Our software products are built on trust, with the
                promise on quality that stems from the wealth of experience in
                product engineering. To keep pace of the technological
                advancements, our teams stay on top of tools and technologies
                that are latest in the market to elevate product engineering to
                the next level. We specialize in low-code/no-code platforms and
                low-code/no-code app development. Besides cloud-based
                applications and enterprise software solutions, we enable
                businesses with highly scalable digital mobility solutions.
              </p>
            </Col>
            <Col
              md={6}
              className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center `}
            >
              <Image
                alt="product-engineering"
                src="/assets/product-engineering-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "450px", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <ServiceKeyPoints
        heading="The following are part of our process:"
        keyPoints={keyPoints}
      />
      <ServiceMainContent caseStudy={caseStudy} />
      <ContactFormService caseStudyLength={1} />
    </>
  );
};

export default ProductEngineering;
