import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./commonCards.module.css";

const CommonCards = ({cardData, title, col}) => {
  
  return (
    <div style={{ marginTop: "2.5em" }}>
      <Container>
        <Row>
          <Col xs={12}>
            <h4 className={styles.heading}>{title}</h4>
          </Col>
        </Row>
        <Row>
          {cardData?.map((obj) => (
            <Col md={col} key={obj.id}>
              <Card
                style={{ width: "100%", borderRadius: "0px", border: "none" }}
              >
                <Card.Img
                  variant="top"
                  src={obj.img}
                  className={styles.aboutimg}
                  alt={obj.title}
                />
                <Card.Body style={{ paddingLeft: "0px" }}>
                  <Card.Title className={styles.title}>{obj.title}</Card.Title>
                  <Card.Text className={styles.desc}>{obj.desc}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CommonCards;
