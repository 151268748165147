import React from "react";
import AboutOverviewSection from "../../components/AboutOverviewSection";
import CommonCards from "../../components/CommonCards";
import AboutPageClients from "../../components/AboutPageClients";
import AboutCeoSection from "../../components/AboutCeoSection";
import AboutHelpBusinesses from "../../components/AboutHelpBusinesses";
import CommonBannerImage from "../../components/CommonBannerImage";
import styles from "./aboutus.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

function AboutUs() {
  const cardData = [
    {
      id: "1",
      title: "EXPERTISE",
      img: "/assets/industry.WebP",
      desc: "Our 100+ software specialists are recognized leaders in their fields, ensuring your success with the best team for your industry.",
    },
    {
      id: "2",
      title: "TRUST",
      img: "/assets/trust.WebP",
      desc: "We prioritize security and privacy, protecting both your data and your company’s reputation.",
    },
    {
      id: "3",
      title: "INNOVATION",
      img: "/assets/innovation.WebP",
      desc: "We lead with cutting-edge technology and products designed to propel your business forward.",
    },
    {
      id: "4",
      title: "COMMITMENT",
      img: "/assets/commitment.WebP",
      desc: `We adhere to the principle of "under promise and over deliver," ensuring quality and reliability in all our services.`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Innovative Technology Consulting Firm - Orbio Solutions</title>
        <meta
          name="description"
          content="Optimize your cloud strategy with Orbio's expert cloud managed services, offering cloud migration, security, and cost-efficient solutions for seamless operations."
        />
        <meta
          name="keywords"
          content="Enterprise software services and digital transformation solution provider for businesses of all sizes"
        />
        <meta
          property="og:title"
          content="Comprehensive Cloud Services and Solutions | Orbio Solutions"
        />
        <meta
          property="og:description"
          content="Discover our expert cloud managed services for seamless migration, optimization, and security. Leading cloud services and solutions for modern businesses."
        />
      </Helmet>
      <CommonBannerImage
        className={styles.CommonBannerImage}
        backgroundImageURL="/assets/about-background4.WebP"
      >
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>About us</h1>
          <h6>
            Leading Technology Consulting Firm: Transforming Business Through
            Innovation
          </h6>
        </div>
      </CommonBannerImage>
      <AboutOverviewSection />
      <AboutCeoSection />
      <AboutHelpBusinesses />
      <div className={`mt-5 ${styles.orbiowaycontainer}`}>
        <Container className="p-5">
          <Row>
            <Col className={styles.orbioContent}>
              <h3 className={`mb-3`}>The Orbio Way</h3>
              <p>
                The Orbio Way is our guiding philosophy for turning ambitious
                ideas into successful realities. We are not just technology
                experts; we are passionate partners dedicated to transforming
                your innovative concepts into tangible results. Our approach is
                personalized and tailored to your unique vision, ensuring that
                our solutions deliver real-world outcomes. We focus on long-term
                collaboration and ongoing improvement to help you achieve
                sustained success. Join us on The Orbio Way and let us transform
                your significant dreams into achievable accomplishments.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <CommonCards cardData={cardData} title={"Our Strengths"} col={3} />
      <AboutPageClients />
    </>
  );
}

export default AboutUs;
