import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./iotDevelopment.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";
import ContactFormService from "../../components/ContactFormService";
import ServiceKeyPoints from "../../components/ServiceKeyPoints";

const IotDevelopment = () => {
  const keyPoints = [
    {
      id: "1",
      title: "Feasibility Study and Requirements Analysis",
      desc: "Conducting in-depth feasibility studies and requirements analysis for tailored IoT solutions.",
    },
    {
      id: "2",
      title: "Design IoT Architecture",
      desc: "Designing sophisticated IoT architectures to integrate and optimize interconnected devices.",
    },
    {
      id: "3",
      title: "Hardware Development and Prototyping",
      desc: "Developing and prototyping advanced IoT hardware for efficient and effective solutions.",
    },
    {
      id: "4",
      title: "Proof-of-concept (POC) Development",
      desc: "Creating proof-of-concept models to demonstrate the practicality and effectiveness of IoT ideas.",
    },
    {
      id: "5",
      title: "Software Development",
      desc: "Engineering cutting-edge software to drive the functionality of IoT systems.",
    },
    {
      id: "6",
      title: "Hardware integration",
      desc: "Seamless hardware-software integration of for a cohesive and functional IoT ecosystem.",
    },
    {
      id: "7",
      title: "Security Implementation",
      desc: "Implementing robust security measures to preserve data integrity and protect IoT systems.",
    },
    {
      id: "8",
      title: "Implement Data Analytics",
      desc: "Utilizing data analytics to enhance IoT system performance and gain valuable insights.",
    },
  ];
  let caseStudy = {
    id: "0",
    heading: "Seamless IoT Integration in existing ERP",
    desc: "Elevating Operations with Expert IoT Consulting and Integration with Existing ERP Systems",
    img: "/assets/iot-lap-screen.WebP",
    url: "/case-study/seamless-IoT-integration-in-existing-erp",
  };
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="IoT technology services company and startup"
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/iot-background.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>IOT Development</h1>
          <h6>
            Cutting-edge IoT development services to enhance functioning of
            interconnected devices & embedded systems
          </h6>
        </div>
      </CommonBannerImage>
      <div>
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <p>
                We are experts in IoT (Internet of Things) design and
                development. We bring together the physical and the digital
                world by creating innovative IoT solutions to build smart
                applications for a seamless user experience. Our IoT framework
                not only brings benefits to our customers but also extends its
                impact to homes, offices, malls, parking lots, traffic signals,
                and restaurants, enhancing connectivity and efficiency across
                diverse environments. Whether you need an automated lighting
                system or fully embedded robotic healthcare devices, our efforts
                are channeled to providing a smart and connected ecosystem for
                your business.
              </p>
            </Col>
            <Col
              md={6}
              className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}
            >
              <Image
                alt="iot"
                src="/assets/iot-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "450px", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <ServiceKeyPoints heading="Our approach:" keyPoints={keyPoints} />
      <ServiceMainContent caseStudy={caseStudy} />
      <ContactFormService caseStudyLength={1} />
    </>
  );
};

export default IotDevelopment;
