import React from "react";
import styles from "./aboutOverview.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { GoDash } from "react-icons/go";

const AboutOverviewSection = () => {
  return (
    <div className="mt-5 mb-5">
      <Container>
        <p>
          At Orbio Solutions, we pride ourselves on being a top-tier{" "}
          <span className="fw-bold">technology consulting firm</span> that
          understands your business needs and delivers customized solutions. Our
          expertise in digital transformation, automation, and data analytics
          ensures that your business not only meets but exceeds its growth
          potential. Join us on a journey of innovation, where your vision
          becomes a reality with Orbio Solutions.
        </p>
        <Row>
          <Col>
            <h4 className={`overViewTitle mt-2 mb-3`}>
              <GoDash style={{ color: "#007fc7" }} />
              Understanding Your Business Is Key To Providing Right Solutions &
              That Is Where We Are Good At.
            </h4>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <p className={styles.overviewdesc}>
              Welcome to Orbio Solutions, a premier technology consulting firm
              dedicated to transforming businesses through digital innovation.
              As a leading tech consulting firm, we offer a comprehensive range
              of services designed to help companies implement, scale, and
              optimize their operations for sustained growth. Our expertise
              spans digital transformation, automation, data analytics, and
              digital enablement, making us a one-stop shop for your technology
              needs.
            </p>
            <p className={styles.overviewdesc}>
              At Orbio Solutions, we believe that understanding your business is
              crucial to delivering the right solutions. Our commitment to
              excellence is reflected in our ability to merge technology, data,
              and design to create impactful results. Our team of experts excels
              in providing end-to-end solutions, including software development,
              data analytics, and advanced digital strategies. We partner with
              you to turn your ideas and aspirations into tangible achievements,
              driving your business success and growth.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutOverviewSection;
