import React from "react";
import styles from "./carousel.module.css";
import { Carousel } from "react-bootstrap";

const CommonCarousel = ({ carouselItems }) => {
  return (
    <div style={{ border: "1px solid black" }}>
      <Carousel fade>
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index} interval={3000}>
            <img className={styles.img} src={item.src} alt={item.alt} />
            <div className={styles.carouselShadow}></div>
            <Carousel.Caption className={styles.carouselCap}>
              <h3>{item.label}</h3>
              <p>{item.text}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CommonCarousel;
